/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { AnimatePresence } from 'framer-motion';
import { Modal } from '@material-ui/core';
import Scroll from 'react-scroll';
import { PhotoLibraryIcon } from '../components/Svg/PhotoLibraryIcon';
import { MenuItemModal } from './MenuItemModal';
import { StarIcon } from '../components/Svg/StarIcon';
import { FavoriteIcon } from '../components/Svg/FavoriteIcon';
import { PrawnIcon } from '../components/Svg/PrawnIcon';
import { SpicyIcon } from '../components/Svg/SpicyIcon';
import { LeafIcon } from '../components/Svg/LeafIcon';

const { Element } = Scroll;

const Root = styled.li`
  display: grid;
  max-width: 37.5rem;
  width: 100%;
  margin: 0 auto;

  padding: ${(props) => (props.isImageAvailable ? '2rem 1rem' : '1rem')};
  /* padding: 1rem; */
  margin-bottom: ${(props) => (!props.isImageAvailable && props.mainCategorySlug === 'beverages' ? `0` : `0`)};
  background-color: ${(props) => (props.isImageAvailable ? props.theme.lowEmphasisBackgroundColor : 'transparent')};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: background-color 0.3s;
  cursor: ${(props) => (props.isImageAvailable ? 'pointer' : 'default')};
  position: relative;

  ::before {
    content: '';
    position: absolute;
    height: calc(100% + 1rem);
    width: calc(100% + 1rem);
    border: ${(props) => (props.id === props.isSearched ? `2px solid ${props.theme.outlinePrimaryColor}` : `none`)};
    top: -0.5rem;
    left: -0.5rem;
    border-radius: ${({ theme }) => theme.borderRadius};
  }

  &:focus {
    background-color: ${({ theme }) => theme.focusedBackgroundColor};
  }

  &:hover {
    background-color: ${({ theme }) => theme.hoveredBackgroundColor};
  }

  .menu-item__image-cell {
    grid-area: image;
    display: ${(props) => (props.isImageAvailable ? 'grid' : 'none')};
    width: 100%;
    justify-content: center;
  }

  .menu-item__image-container {
    margin-bottom: 1rem;
    position: relative;
    width: 6rem;
    height: 6rem;
  }

  .menu-item__photo-library-icon-container {
    position: absolute;
    left: -1.5rem;
    bottom: 0;
    svg {
      fill: ${({ theme }) => theme.onLightColorHighEmphasis};
    }
  }

  .menu-item__title-cell {
    grid-area: title;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-item__title {
    transition: color 0.3s;
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.primaryColor};
    text-align: center;
  }

  .menu-item__variants-cell {
    grid-area: variants;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    margin-left: ${(props) => (props.isImageAvailable ? '0' : '1rem')};
    margin-bottom: ${(props) => (props.isImageAvailable ? '0.5rem' : '0')};
    justify-self: ${(props) => (props.isImageAvailable ? 'center' : 'end')};
  }

  .menu-item__variant-title {
    padding-right: 0.5rem;
    ::after {
      content: '~';
      padding-left: 0.5rem;
    }
  }

  .menu-item__description-cell {
    grid-area: description;
    width: 100%;
    max-width: 100%;
    margin: 0;
    text-align: center;
  }

  .menu-item__description {
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    line-height: 1.5;
    margin-top: -0.25rem;
  }

  grid-template-areas: ${(props) =>
    props.isImageAvailable ? `'image''title''variants''description';` : `'title''variants''description';`};

  text-align: left;
  justify-items: start;

  .food-icon-container {
    display: inline-flex;
    align-items: flex-end;
    svg {
      width: 16px;
      fill: ${({ theme }) => theme.primaryColor};
      margin-left: 0.125rem;
    }
  }

  @media (min-width: 30rem) {
    padding: 1rem;
    grid-template-areas: ${(props) =>
    props.isImageAvailable ? `'title variants''description image';` : `'title variants''description variants';`};

    .menu-item__title-cell {
      justify-content: flex-start;
    }

    .menu-item__image-container {
      width: 5rem;
      height: 5rem;
      margin-top: 1rem;
      margin-left: 2rem;
    }

    .menu-item__title {
      text-align: left;
    }

    .menu-item__variants-cell {
      text-align: right;
    }

    .menu-item__description-cell {
      text-align: left;
    }
  }
`;

const ImageSharp = styled(Img)`
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

const SubCategory = styled.div`
  text-align: center;
  width: 100%;
  display: none;
  margin: 0 auto 1rem;
  margin-top: ${(props) => props.index !== 0 && '2rem'};
  font-weight: 500;
  color: ${({ theme }) => theme.onDarkColorHighEmphasis};
  background-color: ${({ theme }) => theme.secondaryColor};
  height: 3rem;
  justify-content: center;
  align-items: center;
  max-width: 37.5rem;
  border-radius: 0.25rem;
`;

export const MenuItem = ({ data, isSearched, index }) => {
  //

  const [isMenuItemModalOpen, setIsMenuItemModalOpen] = useState(false);

  const {
    name,
    image,
    slug,
    description,
    variants,
    featured,
    favorite,
    spicy,
    vegetarian,
    prawn,
    menuMainCategory: {
      menuMainCategorySlug: { current: mainCategorySlug },
    },
    menuSubCategory,
  } = data;

  const isImageAvailable = !!image;

  const money = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
  });

  const showMenuImages = () => {
    if (isImageAvailable) {
      setIsMenuItemModalOpen(true);
    }
  };

  return (
    <>
      {menuSubCategory && (
        <SubCategory className={`${menuSubCategory.menuSubCategorySlug.current} roboto-text-lg`} index={index}>
          {menuSubCategory.menuSubCategoryName}
        </SubCategory>
      )}
      <Element name={slug.current}>
        <Root
          isImageAvailable={isImageAvailable}
          tabIndex={0}
          id={slug.current}
          isSearched={isSearched}
          onClick={showMenuImages}
          mainCategorySlug={mainCategorySlug}
        >
          <div className="menu-item__image-cell">
            {isImageAvailable && (
              <div className="menu-item__image-container">
                <ImageSharp fluid={image.asset.localFile.childImageSharp.fluid} />
                <div className="menu-item__photo-library-icon-container">
                  <PhotoLibraryIcon />
                </div>
              </div>
            )}
          </div>
          <div className="menu-item__title-cell">
            <div className="roboto-text-lg menu-item__title">
              {name} <span className="food-icon-container">{vegetarian && <LeafIcon />}</span>
              <span className="food-icon-container">{featured && <StarIcon />}</span>
              <span className="food-icon-container">{favorite && <FavoriteIcon />}</span>
              <span className="food-icon-container">{spicy && <SpicyIcon />}</span>
              <span className="food-icon-container">{prawn && <PrawnIcon />}</span>
            </div>
          </div>
          <div className="menu-item__variants-cell">
            <div>
              {variants.map((variant) => {
                return (
                  <div key={variant.variantTitle}>
                    {variant.variantTitle && (
                      <span className="roboto-text-base roboto-text-base--body menu-item__variant menu-item__variant-title">
                        {variant.variantTitle}
                      </span>
                    )}
                    {/* <span className="roboto-text-base roboto-text-base--body menu-item__variant">
                      {money.format(variant.variantPrice)}
                    </span> */}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="menu-item__description-cell">
            <div className="roboto-text-sm roboto-text-sm--body menu-item__description">{description}</div>
          </div>
        </Root>
      </Element>
      <AnimatePresence>
        {isMenuItemModalOpen && (
          <Modal open aria-labelledby="modal for dialog" aria-describedby="modal for dialog">
            <div>
              <MenuItemModal closeFunc={setIsMenuItemModalOpen} image={image} name={name} description={description} />
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

MenuItem.propTypes = {
  data: PropTypes.object.isRequired,
  isSearched: PropTypes.string,
  index: PropTypes.number.isRequired,
};

MenuItem.defaultProps = {
  isSearched: '',
};
