/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
import { Link } from 'gatsby';
import { window } from 'browser-monads';
import { useWindowSize } from '../utils';
import { Container } from '../components/Elements';
import { MenuItem } from './MenuItem';
import { CategorySelector } from './CategorySelector';
import { DownloadIcon } from '../components/Svg/DownloadIcon';
import { UpArrowIcon } from '../components/Svg/UpArrowIcon';
import { LeafIcon } from '../components/Svg/LeafIcon';
import { StarIcon } from '../components/Svg/StarIcon';
import { FavoriteIcon } from '../components/Svg/FavoriteIcon';

const { scroller } = Scroll;

const Section = styled.section`
  padding: 0 0 8rem 0;
  position: relative;
  min-height: ${(props) => `calc(100vh - ${props.footerHeight}px)`};

  .section__heading {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 500;
  }

  .selector-container {
    position: relative;
    height: 3rem;
  }

  .selector {
    position: absolute;
    left: 1rem;
  }

  .download-link {
    position: absolute;
    right: 1rem;
  }

  .menu-grid {
    list-style: none;
    padding-left: 0;
    display: grid;
  }

  .sidenav {
    display: none;
    .sidenav__content {
      padding: 1rem 1rem;
      position: ${(props) => (props.isStickyEnabled ? `sticky` : `relative`)};
      top: ${(props) => props.isStickyEnabled && `80px`};
      border-radius: ${({ theme }) => theme.borderRadius};
      border: ${({ theme }) => `1px solid ${theme.outlinePrimaryColor}`};
      box-shadow: ${({ theme }) => theme.elevation04};
      background-color: ${({ theme }) => theme.defaultSurfaceE04};

      a {
        text-align: left;
        display: block;
        margin-bottom: 0.5rem;
        padding: 0.25rem 0.5rem;
        border-radius: ${({ theme }) => `${theme.borderRadius} ${theme.borderRadius} 0 0`};
        transition: background-color 0.3s;
        text-decoration: none;
        color: ${({ theme }) => theme.onLightSecondaryColor};
        &:hover {
          background-color: ${({ theme }) => theme.hoveredBackgroundColor};
        }
      }

      .sub-category-links {
        margin-bottom: 1.5rem;
        text-align: right;
        padding-top: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 0.5rem;
        border-radius: ${({ theme }) => `0 0 ${theme.borderRadius} ${theme.borderRadius}`};
        background-color: ${({ theme }) => theme.lowEmphasisBackgroundColor};
        margin-top: -0.5rem;
        a {
          cursor: pointer;
          line-height: 1;
          text-decoration: underline;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .active {
      background-color: ${({ theme }) => theme.lowEmphasisBackgroundColor};
    }
  }

  .scroll-to-top {
    position: sticky;
    display: flex;
    justify-content: flex-end;
    bottom: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }

  .legends {
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    box-shadow: ${({ theme }) => theme.elevation02};
    background-color: ${({ theme }) => theme.secondaryVariantColor};
    justify-content: center;
    color: ${({ theme }) => theme.onDarkColorHighEmphasis};
    border-radius: 4px;
    display: flex;
    text-align: center;
    svg {
      width: 16px;
      fill: ${({ theme }) => theme.primaryColor};
      /* margin-right: 0.25rem; */
    }
    .legend {
      margin: 0 1rem;
    }
    .legend-icon {
      display: block;
    }
    position: sticky;
    bottom: 1rem;
  }

  @media (min-width: 32rem) {
    .legends {
      display: flex;
      svg {
        margin-right: 0.25rem;
      }
      .legend-icon {
        display: inline-block;
      }
    }
  }

  @media (min-width: 57.5rem) {
    .section__heading {
      margin-top: 0;
    }
    .sidenav {
      display: block;
      position: absolute;
      margin-left: 1rem;
      min-height: ${(props) => `calc(100vh - ${props.footerHeight}px)`};
      height: 100%;
      left: 0;
      top: 0;
    }
    .menu-grid {
      margin-left: 16rem;
      justify-content: start;
    }
    .selector-container {
      display: none;
    }
  }

  @media (min-width: 75rem) {
    .menu-grid {
      margin-left: 0;
      justify-content: center;
    }
  }
`;

const DownloadMenuLink = styled.a`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.heightDefault};
  white-space: nowrap;
  flex-wrap: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  transition: 0.26s;
  border: none;
  background-color: ${({ theme }) => theme.containedButtonBackgroundColor};
  color: ${({ theme }) => theme.containedButtonTextColor};
  box-shadow: ${({ theme }) => theme.elevation02};
  text-decoration: none;
  width: auto;

  svg {
    fill: ${({ theme }) => theme.containedButtonTextColor};
    margin-left: 0.25rem;
  }

  &:hover {
    .state-overlay {
      opacity: 0.08;
    }
    box-shadow: ${({ theme }) => theme.elevation08};
  }
`;

const IconButton = styled.button`
  background-color: ${({ theme }) => theme.primaryColor};
  background-image: none;
  display: flex;
  padding: 0;
  margin: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  height: ${({ theme }) => theme.heightDefault};
  width: ${({ theme }) => theme.heightDefault};
  border-radius: 50%;
  border: none;
  box-shadow: ${({ theme }) => theme.elevation02};

  svg {
    pointer-events: none;
    fill: ${({ theme }) => theme.onDarkColorHighEmphasis};
  }
`;

export const PageCategoryLayout = ({ pageContext, data, locationState }) => {
  //

  const [isSearched, setIsSearch] = useState('');
  const { categoryName, slug: categorySlug } = pageContext;
  const [footerHeight, setFooterHeight] = useState(null);
  const { width } = useWindowSize();
  const [isStickyEnabled, setIsStickyEnabled] = useState(true);

  const {
    allSanityMenu: { edges: items },
    allSanityMenuMainCategory: { edges: menuCategories },
    allSanityMenuSubCategory: { edges: subCategories },
  } = data;

  useEffect(() => {
    setFooterHeight(document.querySelector('footer').getBoundingClientRect().height);
  }, [width]);

  useEffect(() => {
    const vh = window.innerHeight - 80;
    const sideNavContentHeight = document.querySelector('.sidenav__content').getBoundingClientRect().height;
    setIsStickyEnabled(vh > sideNavContentHeight);
  }, [window.innerHeight]);

  useEffect(() => {
    if (subCategories.length > 0) {
      subCategories.forEach((subCategory) => {
        const el = document.getElementsByClassName(subCategory.node.menuSubCategorySlug.current);
        if (el.length > 0) {
          el[0].style.display = 'flex';
        }
      });
    }
  }, []);

  useEffect(() => {
    if (locationState && locationState.itemId) {
      setIsSearch(locationState.itemId);
      scroller.scrollTo(locationState.itemId, {
        duration: 500,
        smooth: true,
        offset: -80,
      });
    }
  }, [locationState]);

  const handleScrollToSubCategory = (slug) => {
    scroller.scrollTo(slug, {
      duration: 500,
      smooth: true,
      offset: -80,
    });
  };

  const scrollToTop = () => {
    scroller.scrollTo('top', {
      duration: 500,
      smooth: true,
      offset: -80,
    });
  };

  return (
    <Section footerHeight={footerHeight} isStickyEnabled={isStickyEnabled}>
      <Container id="top">
        <div className="selector-container">
          <div className="selector">
            <CategorySelector menuCategories={menuCategories} categorySlug={categorySlug} />
          </div>
          <div className="download-link">
            <DownloadMenuLink
              href="/menu.pdf"
              download
              target="_blank"
              className="roboto-text-sm roboto-text-sm--button"
            >
              Menu
              <DownloadIcon />
            </DownloadMenuLink>
          </div>
        </div>

        <div className="sidenav">
          <div className="sidenav__content">
            {menuCategories.map((category) => {
              return (
                <div key={category.node.id}>
                  <Link
                    className="roboto-text-base roboto-text-base--body"
                    to={`/menu/${category.node.menuMainCategorySlug.current}`}
                    activeClassName="active"
                  >
                    {category.node.menuMainCategoryName}
                  </Link>
                  {category.node.menuMainCategorySlug.current === categorySlug && subCategories.length > 0 && (
                    <div className="sub-category-links">
                      {subCategories.map((subCategory) => {
                        return (
                          <a
                            role="button"
                            className="roboto-text-sm roboto-text-sm--body"
                            key={subCategory.node.menuSubCategorySlug.current}
                            onClick={() => handleScrollToSubCategory(subCategory.node.menuSubCategorySlug.current)}
                            onKeyDown={() => handleScrollToSubCategory(subCategory.node.menuSubCategorySlug.current)}
                            tabIndex={0}
                          >
                            {subCategory.node.menuSubCategoryName}
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <h1 className="roboto-text-xl section__heading">{categoryName}</h1>
        <ul className="menu-grid">
          {items.map((item, index) => {
            return <MenuItem key={item.node.id} data={item.node} isSearched={isSearched} index={index} />;
          })}
          <li className="legends">
            <div className="roboto-text-xs legend">
              <span className="legend-icon">
                <LeafIcon />
              </span>
              Vegetarian
            </div>
            <div className="roboto-text-xs legend">
              <span className="legend-icon">
                <StarIcon />
              </span>
              Chef/TZ Special
            </div>
            <div className="roboto-text-xs legend">
              <span className="legend-icon">
                <FavoriteIcon />
              </span>
              Patron Favourites
            </div>
          </li>
        </ul>
      </Container>
      <div className="scroll-to-top">
        <IconButton onClick={scrollToTop}>
          <UpArrowIcon />
        </IconButton>
      </div>
    </Section>
  );
};

PageCategoryLayout.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  locationState: PropTypes.object,
};

PageCategoryLayout.defaultProps = {
  locationState: {},
};
