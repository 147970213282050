import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useSEO, RestaurantSEO, useGoogleReview } from '../components/SEO';
import { PageCategoryLayout } from './pageCategoryLayout';

const PageByCategory = ({ pageContext, data, location }) => {
  //
  const { pathname, state } = location;
  const {
    allSanityMenu: { edges: menuData },
    allSanityMenuMainCategory: { edges: categories },
  } = data;

  const seo = useSEO(pathname, categories[0].node.menuMainCategoryName);
  const { reviews, rating, totalReviews } = useGoogleReview();

  return (
    <article>
      <RestaurantSEO
        title={categories[0].node.menuMainCategoryName}
        description={seo.description}
        pathname={pathname}
        featureImage="og-default.jpg"
        aggregateRating={{
          ratingValue: rating,
          bestRating: 5,
          worstRating: 0,
          ratingCount: totalReviews,
        }}
        reviews={reviews}
        cuisines={['indian', 'asian']}
        menuTypeName="Dine-In Menu"
        menuTypeDescription="Menu for in-restaurant dining only."
        menuData={menuData}
      />
      <PageCategoryLayout pageContext={pageContext} data={data} locationState={state} />
    </article>
  );
};

export default PageByCategory;

PageByCategory.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query pageByCategory($slug: String!) {
    allSanityMenuMainCategory(sort: { fields: menuMainCategorySortOrder, order: ASC }) {
      edges {
        ...menuMainCategory
      }
    }
    allSanityMenu(
      filter: { menuMainCategory: { menuMainCategorySlug: { current: { eq: $slug } } } }
      sort: { fields: menuSubCategory___menuSubCategorySortOrder, order: ASC }
    ) {
      edges {
        ...allMenu
        ...pageByCategoryImages
      }
    }
    allSanityMenuSubCategory(
      filter: { menuMainCategory: { menuMainCategorySlug: { current: { eq: $slug } } } }
      sort: { fields: menuSubCategorySortOrder, order: ASC }
    ) {
      edges {
        node {
          menuSubCategoryName
          menuSubCategorySlug {
            current
          }
        }
      }
    }
  }
`;
