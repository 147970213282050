/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import { CloseIcon } from '../components/Svg/CloseIcon';
import { IconButton } from '../components/Elements';

const ContentContainerAnimation = {
  hidden: {
    opacity: 0,
    scale: 0.92,
  },
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: 'easeInOut',
      duration: 0.22,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.92,
    transition: {
      ease: 'easeInOut',
      duration: 0.15,
    },
  },
};

const Root = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Scrim = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ContentContainer = styled(motion.div)`
  position: relative;
  z-index: 3;
  background-color: ${({ theme }) => theme.defaultSurfaceE24};
  width: 100%;
  box-shadow: ${({ theme }) => theme.elevation24};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin: 0 0.5rem;

  .content__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    height: 3.5rem;
    color: ${({ theme }) => theme.onLightSecondaryColor};
  }

  .content__media {
    padding: 0 1rem;
  }

  .content__text {
    padding: 1.5rem 1rem;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
    line-height: 1.5;
  }

  @media (min-width: 30rem) {
    width: 28.75rem;
    margin: 0;
  }
`;

const ImageSharp = styled(Img)`
  width: 100%;
  height: 20rem;
`;

export const MenuItemModal = ({ closeFunc, image, name, description }) => {
  //

  return (
    <Root>
      <Scrim onClick={() => closeFunc(false)} />
      <ContentContainer initial="hidden" animate="open" exit="exit" variants={ContentContainerAnimation}>
        <div className="content__header">
          <div className="roboto-text-lg">{name}</div>
          <IconButton onClick={() => closeFunc(false)} modifiers={['noShadow']} ariaLabel="Close Menu item modal">
            <CloseIcon />
          </IconButton>
        </div>
        <div className="content__media">
          <ImageSharp fluid={image.asset.localFile.childImageSharp.fluid} />
        </div>
        <div className="roboto-text-sm roboto-text-sm--body content__text">{description}</div>
      </ContentContainer>
    </Root>
  );
};

MenuItemModal.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
