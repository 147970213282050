/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Select, MenuItem } from '@material-ui/core';

export const CategorySelector = ({ menuCategories, categorySlug }) => {
  //

  const changeRoute = (e) => {
    if (e.target.value !== 'download') {
      navigate(`/menu/${e.target.value}`);
    }
  };

  return (
    <Select variant="outlined" onChange={changeRoute} defaultValue={categorySlug}>
      {menuCategories.map((item) => {
        return (
          <MenuItem key={item.node.id} value={item.node.menuMainCategorySlug.current}>
            {item.node.menuMainCategoryName}
          </MenuItem>
        );
      })}
    </Select>
  );
};

CategorySelector.propTypes = {
  menuCategories: PropTypes.array.isRequired,
  categorySlug: PropTypes.string.isRequired,
};
